/* eslint-disable */ 
export class Utils {
  constructor() {
    this.agent = {browser:{name:null,version:null,v:null,userAgent:null,app:null,os:null},mobile:false,pointlock:false};
    this.nVer = navigator.appVersion;
    this.nAgt = navigator.userAgent;
    this.browserName = navigator.appName;
    this.fullVersion = '' + parseFloat(navigator.appVersion);
    this.majorVersion = parseInt(navigator.appVersion, 10);
    this.nameOffset
    this.verOffset
    this.ix;
    this.agent.pointlock = 'pointerLockElement' in document ||
    'mozPointerLockElement' in document ||
    'webkitPointerLockElement' in document;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((this.verOffset = this.nAgt.indexOf("Opera")) != -1) {
      this.browserName = "Opera";
      this.fullVersion = this.nAgt.substring(this.verOffset + 6);
      if ((this.verOffset = this.nAgt.indexOf("Version")) != -1)
        this.fullVersion = this.nAgt.substring(this.verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((this.verOffset = this.nAgt.indexOf("MSIE")) != -1) {
      this.browserName = "Microsoft Internet Explorer";
      this.fullVersion = this.nAgt.substring(this.verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((this.verOffset = this.nAgt.indexOf("Chrome")) != -1) {
      this.browserName = "Chrome";
      this.fullVersion = this.nAgt.substring(this.verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((this.verOffset = this.nAgt.indexOf("Safari")) != -1) {
      this.browserName = "Safari";
      this.fullVersion = this.nAgt.substring(this.verOffset + 7);
      if ((this.verOffset = this.nAgt.indexOf("Version")) != -1)
        this.fullVersion = this.nAgt.substring(this.verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((this.verOffset = this.nAgt.indexOf("Firefox")) != -1) {
      this.browserName = "Firefox";
      this.fullVersion = this.nAgt.substring(this.verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((this.nameOffset = this.nAgt.lastIndexOf(' ') + 1) <
      (this.verOffset = this.nAgt.lastIndexOf('/'))) {
      this.browserName = this.nAgt.substring(this.nameOffset, this.verOffset);
      this.fullVersion = this.nAgt.substring(this.verOffset + 1);
      if (this.browserName.toLowerCase() == this.browserName.toUpperCase()) {
        this.browserName = navigator.appName;
      }
    }
    if ((this.ix = this.fullVersion.indexOf(";")) != -1)
      this.fullVersion = this.fullVersion.substring(0, this.ix);
    if ((this.ix = this.fullVersion.indexOf(" ")) != -1)
      this.fullVersion = this.fullVersion.substring(0, this.ix);

    this.majorVersion = parseInt('' + this.fullVersion, 10);
    if (isNaN(this.majorVersion)) {
      this.fullVersion = '' + parseFloat(navigator.appVersion);
      this.majorVersion = parseInt(navigator.appVersion, 10);
    }
    this.agent.browser.name = this.browserName;
    this.agent.browser.version = this.fullVersion;
    this.agent.browser.v = this.majorVersion;
    this.agent.browser.app = navigator.appName;
    this.agent.browser.userAgent = navigator.userAgent;
    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

    this.agent.browser.os = OSName;
    this.agent.mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  }

  obtainBrowserName(){
    return this.browserName
  }

  obtainBrowserVersion(){
    return this.fullVersion
  }

  obtainOperativeSystem(){
    return this.agent.browser.os
  }
  
  obtainSystem(){
    return this.agent.browser.os
  }
}