import Vue from "vue";
import PersonalVerification from "./components/PersonalVerification.vue"
import { name as widgetName } from '../package.json';
import Router from "vue-router";

Vue.config.ignoredElements = [ widgetName ];

Vue.use(Router);
const router = new Router({});
new Vue({
    router,
    render: h => h(PersonalVerification)
}).$mount("#app");
