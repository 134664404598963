const axios = require("axios");

export class PreviredServiceTool {
  constructor(kongClient, previredUrl) {
    this.kongClient = kongClient;
    this.baseUrl = `${previredUrl.replace(/\/+$/, "")}/api/v2`;
  }

  async prepareConfig() {
    const token = await this.kongClient.getToken();
    return { Authorization: `Bearer ${token}` };
  }

  async checkBlockedRut(headersData) {
    const config = await this.prepareConfig();
    const url = `${this.baseUrl}/validate/rut`;
    return axios.get(url, {headers: config, params: headersData});
  }
}

export class DummyPreviredServiceTool {
  constructor(previredUrl) {
    this.baseUrl = `${previredUrl.replace(/\/+$/, "")}/api/v2`;
  }
  async checkBlockedRut() {
    return Promise.resolve({ blocked: false });
  }
}
